.Connery-inputs {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	column-gap: 1rem;
}

.Connery ol {
	padding-left: 3rem;
	list-style: decimal;
}

.Connery-inputText {
	flex: 1;
}

.Connery blockquote {
	padding: var(--space-regular) var(--space-larger);
	margin: var(--space-regular) 0;
	color: var(--color-emphasis);
	background-color: var(--color-bg-dark);
	border-radius: var(--border-radius-subtle);
	text-align: left;
	overflow: auto;
	word-wrap: none;
}
