.Autosize-section {
	background-color: var(--color-bg-light);
	border-radius: var(--border-radius-obvious);
	padding: var(--space-small);
	margin: var(--space-large);
	border: 0.1rem solid orange;
}

.Autosize-resize {
	resize: horizontal;
	overflow: hidden;
}

/* ::-webkit-resizer {
	background-color: hotpink;
	width: 30px;
	height: 30px;
} */
