:root {
	--bright: hsl(60deg 100% 95%);
	--bi-1: hsl(   0deg 100% 28%);
	--bi-2: hsl( -25deg 100% 25%);
	--bi-3: hsl( -50deg 100% 30%);
	--bi-4: hsl( -75deg 100% 32%);
	--bi-5: hsl(-90deg 100% 34%);
	--bi-6: hsl(-120deg 100% 36%);
	--bi-grad-rad: repeating-radial-gradient(
		circle at center,
		var(--bi-1) 0,
		var(--bi-2) 20%,
		var(--bi-3) 40%,
		var(--bi-4) 60%,
		var(--bi-5) 80%,
		var(--bi-6) 100%,
		var(--bi-1) 120%
	);
	--bi-grad-lin: linear-gradient(
		var(--bi-1) 0,
		var(--bi-2) 20%,
		var(--bi-3) 40%,
		var(--bi-4) 60%,
		var(--bi-5) 80%,
		var(--bi-6) 100%,
		var(--bi-1) 120%
	);
}

.Paralaxative {
	position: absolute;
	left: 0;
	width: 100vw;
	background: black;
}

.Paralaxative,
.Paralaxative h2 {
	font-family: Arial, Helvetica, sans-serif;
	color: var(--bright);
}

.Paralaxative-section {
	background: var(--bi-grad-rad);
	border-radius: var(--border-radius-obvious);
	margin-bottom: 1%;
}

.bi-grad-lin {
	background: var(--bi-grad-lin);
}

.debug {
	border: 2px limegreen dashed;
}
