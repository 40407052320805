.App {
	position: absolute;

	width: 100%;
	min-height: 75%;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: flex-start;
}

.App-header {
	width: 100%;
	z-index: var(--z-header);
	background-color: var(--color-bg-light);
	padding-bottom: var(--space-small);
}

.App-content {
	width: var(--width-app);
	min-height: var(--height-app);

	background-color: var(--color-bg);
	box-shadow: 0 0 5rem 5rem var(--color-bg);
}

@media (max-width: 26rem) {
	.App-content {
		max-width: var(--width-app);
	}
}

.App-content h1 {
	margin-top: var(--space-large);
	margin-bottom: var(--space-regular);
	font-size: var(--text-largest);
}

.App-content h2 {
	margin-top: var(--space-large);
	margin-bottom: var(--space-small);
	font-size: var(--text-larger);
}

.App-content h3,
.App-content h4,
.App-content h5,
.App-content h6 {
	margin-top: var(--space-large);
	margin-bottom: var(--space-small);
	color: var(--color-text-dim);
}

.App-content p {
	margin: 0.2rem 0 0.5rem 0;
	margin: var(--space-small) 0 var(--space-large) 0;
}

.App-footer {
	flex: 0;
	/* height: calc(5 * var(--space-large)); */
	display: flex;
	align-items: flex-end;
	padding-bottom: 2rem;
}

.App-footer a {
	color: var(--color-bg-light);
}

.Header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-end;

	margin: var(--space-small) 3%;
}

.Header-title {
	color: var(--color-text);
	margin-right: var(--space-large);
}

.Header-title a {
	color: var(--color-text-light);
	text-decoration: none;
}

.Nav {
	flex: 1;
	padding-bottom: var(--space-regular);
}

.Nav-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.Nav-item {
	margin-right: var(--space-large);
	border-radius: var(--border-radius-subtle);
}

.Nav-item a {
	text-decoration: none;
}

.Nav-item a.is-active {
	text-decoration: underline;
}
