.Pop {
	display: inline;
}
.Pop-content {
	/* Box appearance: */
	background-color: var(--color-bg-dark);
	box-shadow: 0 0 0.5rem 0.5rem var(--color-bg-dark);
	border-radius: var(--border-radius-subtle);

	/* Overlay content: */
	position: absolute;
	z-index: 10;

	/* Expand/collapse animation: */
	transition: transform 100ms ease-in-out;
	transform-origin: top;
	overflow: hidden;
}
.Pop-content.is-hidden {
	/* display: none; */
	transform: scaleY(0);
}
.Pop-content.is-visible {
	height: fit-content;
	transform: scaleY(1);
}
