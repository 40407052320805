.MdPage ol {
	padding-left: 3rem;
	list-style: decimal;
}

.MdPage ul {
	padding-left: 3rem;
	list-style: disc;
}

.MdPage .img-side {
	max-width: 256px;
	float: right;
	margin: var(--space-large) 0 var(--space-large) var(--space-large);
	border-radius: var(--border-radius-subtle);
}

.MdPage .img-center {
	display: block;
	max-width: 256px;
	margin: 0 auto;
	border-radius: var(--border-radius-subtle);
}

.MdPage img {
	max-width: 100%;
}

.MdPage figure {
	margin: var(--space-large) 0;	
}

.MdPage figcaption {
	margin-top: var(--space-regular);
	width: 100%;
	text-align: center;
	display: block;
	font-size: var(--text-smaller);
	font-style: italic;
}

@media (max-width: 50rem) {
	.MdPage .img-side {
		max-width: 40%;
	}
	
	.MdPage .img-center {
		max-width: 85%;
	}

	.MdPage pre {
		width: 95%;
		overflow: scroll;
	}
}
