.Nav {
	flex: 1;
	padding-bottom: var(--space-regular);
}

.Nav-hamburger {
	background: none;
	border-radius: var(--border-radius-circle);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2%;
	font-size: var(--text-larger);
}

.Nav-drawer {
	/* Box appearance: */
	background-color: var(--color-bg-dark);
	box-shadow: 0 0 0.5rem 0.5rem var(--color-bg-dark);
	border-radius: var(--border-radius-subtle);

	/* Overlay content: */
	position: absolute;
	z-index: 10;

	/* Expand/collapse animation: */
	transition: transform 100ms ease-in-out;
	transform-origin: top;
	overflow: hidden;
}
.Nav-drawer.is-collapsed {
	transform: scaleY(0);
}
.Nav-drawer.is-expanded {
	height: fit-content;
	transform: scaleY(1);
}

/* .Nav-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
} */

.Nav-item {
	margin-right: var(--space-large);
	border-radius: var(--border-radius-subtle);
}

.Nav-item a {
	text-decoration: none;
}

.Nav-item a.is-active {
	text-decoration: underline;
}
