:root {
	--letter-width: calc(min(6.8vw, 45px));
	/* --letter-width: 45px; */
	--gap: calc(var(--space-regular));
}

.Dictle {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: center;
	min-height: 600px;
	width: 100%;
	/* overflow: hidden; */
}
.Dictle-header {
	height: 10vh;
}
.Dictle-panels {
	height: 60vh;

	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: flex-start;
	column-gap: var(--gap);
	margin-bottom: var(--gap);
}
.Dictle-panelLeft {
	/* flex: 2; */
}
.Dictle-panelRight {
	/* flex: 3; */
}
.Dictle-keyboard {
	height: 20vh;

	display: flex;
	justify-content: center;
	align-items: center;
}

.DictleHeader {
	display: flex;
	flex-flow: column nowrap;
	/* justify-content: center; */
	align-items: center;
	margin-top: -10px;
}
.DictleHeader-title {
	display: flex;
	justify-content: center;
	align-items: baseline;
	column-gap: calc(2 * var(--gap));
}
.DictleHeader-title h2 {
	font-style: italic;
	font-weight: 500;
}
.DictleHeader-links {
	display: flex;
	column-gap: calc(2 * var(--gap));
}

.DictleHeader-pop {
	left: 15%;
	width: 70%;
}

.HowToPlay {
	padding: var(--gap);
}

.Stats {
	padding: var(--gap);
}

.PopButton {
	all: unset;
	text-decoration: underline;
	color: var(--color-link);
}
.PopButton:hover {
	color: var(--color-link-hover);
	cursor: pointer;
}
.PopButton:focus {
	color: var(--color-link-active);
}

/* .Guesses {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
} */
.Guesses-list {
	max-height: 55vh;

	display: flex;
	flex-flow: column nowrap;
	overflow-y: scroll;
	row-gap: var(--gap);

	padding-right: calc(2 * var(--gap));
}
.Guess-container {
	display: flex;
	flex-flow: row nowrap;
	column-gap: var(--gap);
}
.Guess-container.is-invalid {
	animation: shake 0.4s cubic-bezier(0.3, 0.1, 0.2, 1) both;
	transform: translate3d(0, 0, 0);
}
@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
.Guesses-input {
	margin-top: var(--gap);
}

.Guess-letter {
	width: var(--letter-width);
	height: var(--letter-width);
	/* width: 50px;
	height: 50px; */

	border-radius: var(--border-radius-subtle);
	border: 2px solid white;

	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	vertical-align: middle;

	font-weight: 600;
	font-size: var(--text-largest);
	text-transform: capitalize;
	color: white;
}
.Guess-letter.is-match {
	background-color: #4b9244;
}
.Guess-letter.is-close {
	background-color: #b39305;
}
.Guess-letter.is-miss {
	background-color: #616263;
}
.Guess-letter.is-blank {
	background-color: black;
}

.Dict {
	flex: 1;
	font-family: 'Courier New', Courier, monospace;
}
.Dict-completed {
	padding: calc(var(--gap) / 2) var(--gap);
}
.Dict-input {
	padding: calc(var(--gap) / 2) var(--gap);
	width: fit-content;
	border-radius: var(--border-radius-subtle);
}
.Dict-input.is-checked {
	border: solid 2px var(--color-text-darkest);
	box-shadow: 0 0 4px 8px var(--color-bg-light) inset;
}
.Dict-input:active {
	box-shadow: 0 0 4px 8px var(--color-bg-light) inset;
}
.Dict-input input {
	all: unset;
	appearance: none;
}
.Dict-more {
	margin: var(--gap);
}

.DictleLoading {
	display: flex;
	flex-direction: row wrap;
	margin: var(--gap);
}
.DictleLoading > div {
	animation: slideIn 1.5s linear;
	animation-iteration-count: infinite;
	animation-delay: calc(150ms * var(--i));
	transform: translate3d(0, 0, 0);
}
@keyframes slideIn {
	10% {
		transform: translate3d(100%, 0, 0);
	}
	20% {
		transform: translate3d(0%, 0, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
}

.Keyboard {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	row-gap: var(--gap);
}
.Keyboard-row {
	flex: 1;

	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	column-gap: var(--gap);
}
.Keyboard-key,
.Keyboard-letter {
	all: unset;
	user-select: none;

	width: var(--letter-width);
	min-height: 5vh;
	max-height: calc(1.5 * var(--letter-width));

	border-radius: var(--border-radius-subtle);
	border: 2px solid white;

	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	vertical-align: middle;

	font-weight: 500;
	font-size: var(--text-large);
	text-transform: capitalize;
	color: white;
}
.Keyboard-key {
	width: calc(2 * var(--letter-width));
}
.Keyboard-letter {
	width: var(--letter-width);
}
.Keyboard-key:hover,
.Keyboard-letter:hover {
	cursor: pointer;
}
.Keyboard-key:active,
.Keyboard-letter:active {
	box-shadow: 0 0 4px 8px var(--color-bg-light) inset;
}
.Keyboard-letter.is-match {
	background-color: #4b9244;
}
.Keyboard-letter.is-close {
	background-color: #b39305;
}
.Keyboard-letter.is-miss {
	background-color: #616263;
}
